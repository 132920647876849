import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Layout from "../components/layout";

import Seo from "../components/seo";

function NotFound() {
  return (
    <Layout>
      <Seo
        title="Sivua ei löydy"
        description="Hakemaasi sivua ei löydy"
      ></Seo>
      <Grid container justify="center" alignItems="center">
        <Grid item xs={12}>
          <Typography variant="h3">Sivua ei löydy</Typography>
          <Typography variant="subtitle1">
            Hakemaasi sivua ei ole tai se on siirretty
          </Typography>
        </Grid>
      </Grid>
    </Layout>
  );
}

export default NotFound;
